<!-- eslint-disable  @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable   @angular-eslint/template/interactive-supports-focus -->
<ng-container>

  <div class="tds-card">
    <div class="tds-card-header">
      <div class="tds-card-header-title">
        <a class="lienRetour" (click)="goBack()"
          style="display: inline; margin-left: 10px;margin-right: 10px;margin-bottom: 20px;vertical-align: middle;">
          <mat-icon>
            arrow_back
          </mat-icon>
        </a>
        <h2 i18n="@@MentionsLegales.Headline">MENTIONS LEGALES</h2>
      </div>
    </div>

    <div class="tds-card-content">

      <div class="tds-card-content-ct">

        <div class="tds-subcards">

          <div class="tds-subcards-item" i18n="@@MentionsLegales.Editeur">
            <p><strong>Éditeur du site :</strong></p>
            <p><strong>MSH INTERNATIONAL</strong></p>
            <p>Société par actions simplifiée au capital de 2 500 000 €</p>
            <p>Immatriculée au RCS de Paris sous le n°352 807 549</p>
            <p>Société de courtage d'assurance immatriculée à l'ORIAS sous le n° 07 002 751 - www.orias.fr</p>
            <p>Siège social : 39 rue Mstislav Rostropovitch - 75017 Paris - France</p>
            <p>Société soumise au contrôle de l'Autorité de Contrôle Prudentiel et de Résolution - 4 Place de Budapest - CS 92459 - 75436 Paris Cedex 09</p>

            <p>N° de TVA intracommunautaire : FR 78 352 807 549</p>

            <p>Tél : +33144202998</p>
            <p>contact&#64;msh-intl.com</p>

            <p><strong>Directeur de la publication :</strong></p>
            <p>Frédéric Grand, Président</p>

            <p><strong>Hébergeur du site :</strong></p>
            <p>Sopra Steria Group</p>
            <p>PAE Les Glaisins - 3, rue du Pré Faucon - ANNECY LE VIEUX - 74940 ANNECY - France</p>
            <p>Tél. : +33140672929</p>
          </div>

          <div class="tds-subcards-item" i18n="@@MentionsLegales.Copyright">
            <p><strong>DROIT D'AUTEUR - COPYRIGHT</strong></p>
            <p>Tous droits réservés © MSH International 2024. Tous les contenus de toute nature (images fixes ou animées, bases de données, marques, illustrations, logos, dessins, modèles, mises en page, documents téléchargeables) contenus dans ce site sont protégés au titre des œuvres de l'esprit par le droit d'auteur français et international. Sauf indication contraire, ces contenus restent la propriété exclusive de MSH International. Cela signifie que sous peine de poursuite pénale, aucun élément du site ne peut être reproduit, représenté, adapté, partiellement ou totalement modifié sous quelque forme et par quelque procédé que ce soit sans autorisation écrite préalable.</p>

            <p>Les marques et logos reproduits sur ce site Internet sont déposés. Toute reproduction, republication ou diffusion intégrale ou partielle des marques et logos par quelque procédé que ce soit, sans autorisation écrite préalable, est strictement interdite, conformément à l'article L.713-2 du Code de la propriété intellectuelle.</p>
          </div>

          <div class="tds-subcards-item" i18n="@@MentionsLegales.Loi">
            <p><strong>LOI APPLICABLE</strong></p>
            <p>Ce site Internet est régi par les lois de la France. Tout litige sera jugé devant les tribunaux français.</p>
          </div>

          <div class="tds-subcards-item" i18n="@@MentionsLegales.Cookies">
            <p><strong>INFORMATIONS SUR LES COOKIES</strong></p>
            <p>MSH International, société du groupe DIOT-SIACI, a réalisé cette fiche pour préciser comment nous, ou nos partenaires, utilisons les cookies sur notre site Internet. Cette fiche vous donnera également toutes les informations nécessaires à la gestion de votre consentement aux cookies déposés sur vos appareils. Cette fiche est susceptible d'être mise à jour, notamment pour des raisons de conformité aux évolutions légales et réglementaires. Nous vous recommandons donc de la consulter régulièrement.</p>

            <p><strong>QU'EST-CE QU'UN COOKIE ?</strong></p>
            <p>Les cookies sont de petits fichiers déposés et stockés sur vos appareils (ordinateur, tablette, téléphone mobile, etc.) pour nous permettre de recueillir des informations sur la façon dont vous naviguez sur notre site Internet.</p>

            <p><strong>QUELS TYPES DE COOKIES UTILISONS-NOUS ET POURQUOI ?</strong></p>
            <p>Voici les différents types de cookies que nous utilisons sur notre site Internet pour les finalités suivantes :</p>

            <p><strong>&nbsp;&nbsp;• Cookies fonctionnels</strong></p>

            <p>Ces cookies vous permettent de naviguer sur notre site Internet et d'utiliser ses différentes fonctionnalités.</p>

            <p>Ils comprennent :</p>
            <p>&nbsp;&nbsp;• Cookies de session.</p>
            <p>&nbsp;&nbsp;• Cookies nécessaires à la sécurité de notre site Internet, pour éviter un accès frauduleux à votre session par exemple.</p>
            <p>Ces cookies ne nécessitent pas votre consentement.</p>

            <p><strong>&nbsp;&nbsp;• Cookies de préférence</strong></p>
            <p>Ces cookies aident à personnaliser votre expérience de navigation sur notre site Web en mémorisant votre langue, votre pays et d'autres préférences.</p>

            <p><strong>&nbsp;&nbsp;• Cookies d'analyse Web</strong></p>
            <p>Ces cookies nous aident à analyser votre navigation et votre comportement sur notre site Internet pour identifier d'éventuels problèmes et améliorer notre contenu, par exemple en analysant les pages sur lesquelles vous cliquez, la durée de votre connexion, les pages que vous consultez lorsque vous quittez notre site, etc.</p>
            <p>Ces cookies ne nécessitent pas votre consentement sauf dans certains cas prévus par la loi (voir le site de la CNIL https://www.cnil.fr/fr/cookies-traceurs-que-dit-la- loi). Cependant, vous pouvez vous y opposer à tout moment.</p>

            <p><strong>&nbsp;&nbsp;• Cookies publicitaires</strong></p>
            <p>Ces cookies nous aident à vous proposer des contenus publicitaires pertinents et personnalisés (sous forme de bannières publicitaires) en fonction de votre navigation. Notre site Internet utilise également des cookies tiers qui auront accès à vos données de navigation à des fins de publicité ciblée.</p>
            <p>Ces cookies nécessitent votre consentement préalable.</p>

            <p><strong>&nbsp;&nbsp;• Cookies des réseaux sociaux</strong></p>
            <p>Nous vous proposons des boutons de partage de contenus via les réseaux sociaux (Facebook et LinkedIn). Les éditeurs de ces réseaux sociaux sont susceptibles de déposer des cookies directement sur votre terminal pour identifier votre session de navigation sur notre site internet, même si vous ne cliquez pas sur ces boutons.</p>
            <p>Ces cookies nécessitent votre consentement préalable.</p>

            <p><strong>QUI PEUT PLACER DES COOKIES SUR VOS APPAREILS ?</strong></p>
            <p>Les cookies mentionnés ci-dessus peuvent être déposés par nous ou par des partenaires tiers, sous réserve de votre consentement. Cela signifie que ces tiers peuvent avoir un accès direct à vos données de navigation. Ci-dessous un tableau reprenant les cookies déposés sur vos appareils et les partenaires tiers correspondants. Nous vous recommandons de lire leur politique en matière de cookies directement sur leur site Internet.</p>

            <p><strong>COMBIEN DE DURÉE VOTRE CONSENTEMENT EST-IL VALABLE ?</strong></p>
            <p>La durée de votre consentement dépend du cookie concerné mais ne peut en aucun cas excéder une durée de 6 mois maximum.</p>

            <p><strong>QUELS SONT VOS DROITS ?</strong></p>
            <p>Ce bandeau vous demandait d'accepter tous nos cookies, de les refuser ou de personnaliser leur paramétrage. Un bandeau d'information s'affichait lors de votre visite sur notre site internet.</p>
            <p>Vous pouvez gérer votre consentement à tout moment en cliquant <button id="consentementBtn" (click)="onUtilisateurACliqueSurBoutonGererConsentement()">ici</button>.</p>
            <p>Si vous avez des questions, veuillez contacter notre délégué à la protection des données à dpo&#64;s2hgroup.com.</p>

            <p>Cookies utilisés sur notre site Internet</p>

            <p>Retrouvez le Tableau des cookies en cliquant <a target="_blank" class="cookiesLink"
              href="{{baseUrl}}documents/{{codeProduitActuel}}/cookies/{{currentLanguage}}.pdf">ici</a>.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>
